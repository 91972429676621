import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const confidence = () => (
  <Layout>
    <SEO title="Bananes Citronnées - Confidence" />
    <h3 className='underline-title'>Confidence</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Je voudrais te dire des choses dures<br />
        - dures commes des cailloux<br />
        Encastrer ces choses dans ta cervelle<br />
        - cervelle dédaigneuses<br />
        Ces choses<br />
        - que seuls comprennent les Nippons d'Hiroshima-Nagasaki<br />
        Tu es trop haut perché<br />
        Pour entendre l'écho lointain du gémissement<br />
        Écho mille fois répercuté<br />
        - s'évanouissant avant l'Empyrée<br />
        Murmures étouffés<br />
        - de tempetes cycloniques dans l'atmosphère<br />
        Il faut que tu saches:<br />
        Les volcans s'apprêtent aux stratégies éruptives<br />
        Les lunettes myopes de la Terre sont brisées<br />
        Elle en devient RAY Charles<br /> 
        - l'aveugle-né-chanteur-des-bleues<br />
      </p>
      <p>
        A quoi servent encore ces mini-écrivaillons?<br />
        Et ces rêveurs flémards du philosophisme?<br />
        Ces fariboles du sophisme?<br />
        Cette orchestration des platitude?<br />
        Elles n'apprennent point à vivre?<br />
      </p>
      <p>
        C'est l'ère D<br />
        A chacun des dollars<br />
        Monnaie eschatologique<br />
        Pourvu qu'on t'atteigne un jour<br />
        En fumée noire<br />
        Fumée d'incendie<br />
        Incendie titanesque d'Apocalypse<br />
        Ainsi soit-il<br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/bananes-citronnees-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default confidence
